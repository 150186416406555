/* CSS variables. */
.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	/*flex: 1;*/
	/* The phone number input should shrink
	   to make room for the extension input */
	/*min-width: 0;*/
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.bluepin-placeholder::-webkit-input-placeholder {
    color: #e4e4e5;
	opacity: 1;

}

.bluepin-placeholder::-moz-placeholder {
	color: #e4e4e5;
	opacity: 1;

}

.bluepin-placeholder:-ms-input-placeholder {
	color: #e4e4e5;
	opacity: 1;
}

.bluepin-placeholder::-ms-input-placeholder {
	color: #e4e4e5;
	opacity: 1;
	
}

.bluepin-placeholder::placeholder {
	color: #e4e4e5;
	opacity: 1;
}

textarea.bluepin-input {
    height: auto;
}

.bluepin-input{
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}